html {
  scroll-behavior: smooth;
}

body {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 26px;
  color: #3F3E36;
  font-weight: 400;
  background-color: rgba(63, 62, 54, 0.01);
}

strong {
  font-weight: 700;
}

h1, h2, h3 {
  font-weight: 500;
}

.page-container {
  width: 1200px;
  max-width: 95%;
  margin: 0 auto;
  background-color: #FFFFFF;
  box-shadow: 0 0 12px rgba(44, 43, 38, 0.2);
}

a {
  color: #3F3E36;
}
a:hover {
  color: #2c2b26;
  text-decoration: underline;
}

blockquote {
  margin: 70px auto;
  padding: 25px;
  color: #2c2b26;
  border: none;
  text-align: center;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
}

.button {
  padding: 15px;
  border: 2px solid #2ea3f2;
  background-color: #FFFFFF;
  color: #2ea3f2;
  line-height: 1;
  border-radius: 8px;
  font-weight: 700;
  display: inline-block;
  transition: all ease-in-out 0.3s;
}
.button:hover {
  background-color: #2ea3f2;
  color: #FFFFFF;
  text-decoration: none;
}
.button--light {
  border: 2px solid #FFFFFF;
  background-color: transparent;
  color: #FFFFFF;
}
.button--light:hover {
  background-color: transparent;
  color: #2ea3f2;
  border-color: #2ea3f2;
}

.et_menu_container {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.header-contact {
  text-align: right;
  padding: 10px 25px;
  font-weight: 500;
  background-color: #2c2b26 !important;
}
.header-contact .phone,
.header-contact .email {
  padding-left: 20px;
  display: inline-flex;
  align-items: center;
}
.header-contact .phone .lni,
.header-contact .email .lni {
  margin-right: 6px;
}

#main-header {
  padding: 10px 25px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
#main-header #logo {
  width: 320px;
}
@media (max-width: 768px) {
  #main-header #logo {
    width: 180px;
  }
}
#main-header .mobile-nav {
  display: none;
  align-self: flex-end;
  margin-left: auto;
}
@media (max-width: 980px) {
  #main-header .mobile-nav {
    width: 30px;
    height: 30px;
    line-height: 1;
    display: grid;
    place-items: center;
    background-color: transparent;
    border: none;
    font-size: 22px;
  }
}
#main-header .mobile-nav .lni-close {
  display: none;
}
#main-header .mobile-nav .lni-menu {
  display: block;
}
#main-header .mobile-nav.nav-open .lni-close {
  display: block;
}
#main-header .mobile-nav.nav-open .lni-menu {
  display: none;
}
#main-header .navigation a:hover {
  color: #2c2b26;
}
#main-header .navigation.nav-open {
  flex: 0 1 100%;
}
#main-header .navigation.nav-open #top-menu {
  width: 100%;
  display: block;
  margin-top: 20px;
}
#main-header .navigation.nav-open #top-menu .menu-item {
  display: block;
  text-align: center;
  padding-right: 0;
}
#main-header .navigation.nav-open #top-menu .menu-item a {
  font-size: 18px;
  font-weight: 700;
  line-height: 2;
}

.main-content {
  padding: 80px 25px;
  background-size: cover;
  background-position: top left;
  background-color: #3F3E36;
  background-blend-mode: overlay;
}
.main-content__text {
  text-align: left;
  color: #FFFFFF;
  max-width: 50%;
}
@media (max-width: 980px) {
  .main-content__text {
    max-width: 80%;
  }
}
@media (max-width: 768px) {
  .main-content__text {
    max-width: 100%;
    text-align: center;
  }
}
.main-content__text .bigger {
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 30px;
}
@media (max-width: 768px) {
  .main-content__text .bigger {
    font-size: 16px;
  }
}
.main-content__text h1 {
  font-weight: 700;
  color: #FFFFFF;
}

.about-us {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 4%;
}
.about-us__image {
  margin: 0;
  flex: 0 1 40%;
}
@media (max-width: 980px) {
  .about-us__image {
    flex: 0 1 46%;
  }
}
@media (max-width: 768px) {
  .about-us__image {
    flex: 0 1 100%;
  }
}
.about-us__text {
  padding: 20px 50px;
  flex: 0 1 56%;
}
@media (max-width: 980px) {
  .about-us__text {
    flex: 0 1 50%;
    padding: 20px 25px;
  }
}
@media (max-width: 768px) {
  .about-us__text {
    flex: 0 1 100%;
    padding: 20px 25px;
  }
}

.footer {
  border-bottom: 20px solid #2c2b26;
  background-color: rgba(63, 62, 54, 0.05);
}
.footer__inner {
  padding: 40px 25px;
  text-align: center;
}
.footer__title {
  font-weight: 700;
  line-height: 1.5;
  margin-bottom: 15px;
  font-size: 26px;
  text-align: center;
}
.footer__title span {
  color: #FDD300;
}
.footer__adress {
  margin-bottom: 15px;
}
.footer__subtitle {
  font-size: 22px;
}
.footer__phone, .footer__email {
  margin: 5px auto;
  line-height: 1;
  display: inline-flex;
  align-items: center;
}
.footer__phone .lni, .footer__email .lni {
  margin-right: 8px;
}

.services {
  margin-top: 40px;
  text-align: center;
  padding: 25px 25px 0;
}
.services .lni {
  font-size: 30px;
  color: #2c2b26;
  margin-top: 20px;
}
.services__list {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 40px;
  flex-wrap: wrap;
}
.services__list > * {
  flex: 0 1 30%;
  text-align: center;
  padding: 30px;
}
@media (max-width: 980px) {
  .services__list > * {
    flex: 0 1 100%;
  }
}
.services__list > * .lni {
  font-size: 30px;
  color: #FDD300;
  border: 2px solid #FDD300;
  padding: 20px;
  border-radius: 200px;
  margin-bottom: 15px;
}
.services__list > * h4 {
  font-weight: 700;
  color: #2c2b26;
}
.services__list > * p {
  font-size: 14px;
  line-height: 24px;
}