html {
	scroll-behavior: smooth;
}

body {
	font-family: 'Open Sans', sans-serif;
	font-size: 16px;
	line-height: 26px;
	color: $grey;
	font-weight: 400;
	background-color: fade-out($grey, .99);
}

strong {
	font-weight: 700;
}

h1, h2, h3 {
	font-weight: 500;
}

.page-container {
	width: 1200px;
	max-width: 95%;
	margin: 0 auto;
	background-color: $white;
	box-shadow: 0 0 12px fade-out($grey-dark, .8);
}

a {
	color: $grey;

	&:hover {
		color: $grey-dark;
		text-decoration: underline;
	}
}

blockquote {
	margin: 70px auto;
	padding: 25px;
	color: $grey-dark;
	border: none;
	text-align: center;
	font-weight: 500;
	font-size: 20px;
	line-height: 32px;
}

.button {
	padding: 15px;
	border: 2px solid $blue;
	background-color: $white;
	color: $blue;
	line-height: 1;
	border-radius: 8px;
	font-weight: 700;
	display: inline-block;
	transition: all ease-in-out .3s;

	&:hover {
		background-color: $blue;
		color: $white;
		text-decoration: none;
	}

	&--light {
		border: 2px solid $white;
		background-color: transparent;
		color: $white;

		&:hover {
			background-color: transparent;
			color: $blue;
			border-color: $blue;
		}
	}
}

.et_menu_container {
	align-items: center;
	display: flex;
	justify-content: space-between;
}

.header-contact {
	text-align: right;
	padding: 10px 25px;
	font-weight: 500;
	background-color: $grey-dark !important;

	.phone,
	.email {
		padding-left: 20px;
		display: inline-flex;
		align-items: center;

		.lni {
			margin-right: 6px;
		}
	}
}

#main-header {
	padding: 10px 25px;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;


	#logo {
		width: 320px;

		@media (max-width: 768px) {
			width: 180px;
		}
	}

	.mobile-nav {
		display: none;
		align-self: flex-end;
		margin-left: auto;

		@media (max-width: 980px) {
			width: 30px;
			height: 30px;
			line-height: 1;
			display: grid;
			place-items: center;
			background-color: transparent;
			border: none;
			font-size: 22px;
		}

		.lni-close {
			display: none;
		}

		.lni-menu {
			display: block;
		}

		&.nav-open {
			.lni-close {
				display: block;
			}

			.lni-menu {
				display: none;
			}
		}
	}

	.navigation {
		a {
			&:hover {
				color: $grey-dark;
			}
		}

		&.nav-open {
			flex: 0 1 100%;

			#top-menu {
				width: 100%;
				display: block;
				margin-top: 20px;

				.menu-item {
					display: block;
					text-align: center;
					padding-right: 0;

					a {
						font-size: 18px;
						font-weight: 700;
						line-height: 2;
					}
				}
			}
		}
	}
}

.main-content {
	padding: 80px 25px;
	background-size: cover;
	background-position: top left;
	background-color: $grey;
	background-blend-mode: overlay;

	&__text {
		text-align: left;
		color: $white;
		max-width: 50%;

		@media (max-width: 980px) {
			max-width: 80%;
		}

		@media (max-width: 768px) {
			max-width: 100%;
			text-align: center;
		}

		.bigger {
			font-size: 18px;
			line-height: 30px;
			margin-bottom: 30px;

			@media (max-width: 768px) {
				font-size: 16px;
			}
		}

		h1 {
			font-weight: 700;
			color: $white;
		}
	}
}

.about-us {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	gap: 4%;

	&__image {
		margin: 0;
		flex: 0 1 40%;

		@media (max-width: 980px) {
			flex: 0 1 46%;
		}

		@media (max-width: 768px) {
			flex: 0 1 100%;
		}
	}

	&__text {
		padding: 20px 50px;
		flex: 0 1 56%;

		@media (max-width: 980px) {
			flex: 0 1 50%;
			padding: 20px 25px;
		}

		@media (max-width: 768px) {
			flex: 0 1 100%;
			padding: 20px 25px;
		}
	}
}

.footer {
	border-bottom: 20px solid $grey-dark;
	background-color: fade-out($grey, .95);

	&__inner {
		padding: 40px 25px;
		text-align: center;
	}

	&__title {
		font-weight: 700;
		line-height: 1.5;
		margin-bottom: 15px;
		font-size: 26px;
		text-align: center;

		span {
			color: $yellow;
		}
	}

	&__adress {
		margin-bottom: 15px;
	}

	&__subtitle {
		font-size: 22px;
	}

	&__phone,
	&__email {
		margin: 5px auto;
		line-height: 1;
		display: inline-flex;
		align-items: center;

		.lni {
			margin-right: 8px;
		}
	}
}

.services {
	margin-top: 40px;
	text-align: center;
	padding: 25px 25px 0;

	.lni {
		font-size: 30px;
		color: $grey-dark;
		margin-top: 20px;
	}

	&__list {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		margin-bottom: 40px;
		flex-wrap: wrap;

		> * {
			flex: 0 1 30%;
			text-align: center;
			padding: 30px;

			@media (max-width: 980px) {
				flex: 0 1 100%;
			}

			.lni {
				font-size: 30px;
				color: $yellow;
				border: 2px solid $yellow;
				padding: 20px;
				border-radius: 200px;
				margin-bottom: 15px;
			}

			h4 {
				font-weight: 700;
				color: $grey-dark;
			}

			p {
				font-size: 14px;
				line-height: 24px;
			}
		}
	}
}